import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

const ProductList = (props) => {
  const [obj, setObj] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const List = (object) => {
    return (
      <>
        <div
          className={`card ${!props.showImage && "card-mouse"}`}
          onClick={() => {
            if (props.page !== "CLAY") {
              setObj(object);
              handleShow();
            }
          }}
        >
          {props.showImage && (
            <>
              {object.src && (
                <img
                  src={object.src}
                  className="card-img-top  card-popup img-fluid"
                  alt={object.name}
                />
              )}
            </>
          )}

          <div className="card-body custom-body">
            <h5 className="card-title">{object.name}</h5>
            {object &&
              object.details &&
              object.details.map((txt) => {
                return <p className="card-text">{txt}</p>;
              })}
          </div>
        </div>
      </>
    );
  };

  const ModalPopup = () => {
    const [index, setIndex] = React.useState(0);
    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
    return (
      <>
        <Modal show={show} onHide={handleClose} className="modal-xl">
          <Modal.Header closeButton>
            <Modal.Title>
              {obj.name}({obj.prodcuctid}
              {obj.productid})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="g-4">
              <Col md={6}>
                {!obj.images &&(
                  <img
                    src={obj.src}
                    className="card-img-top  card-popup img-popup"
                    alt={obj.name}
                  />
                )}
                
                {obj.images && obj.images.length > 0 && (
                  <Carousel
                    // autoPlay
                    activeIndex={index}
                    onSelect={handleSelect}
                  >
                    {obj.images.map((item) => {
                      return (
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={item}
                            alt={obj.name}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                )}
              </Col>
              <Col md={6}>
                <table className="table table-bordered product-info-table">
                  <tbody>
                    {obj.per_100 && (
                      <tr className="product-row">
                        <td className="product-heading">Per 100 SQ FT: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.per_100,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.productid && (
                      <tr className="product-row">
                        <td className="product-heading">Product ID: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.productid,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.prodcuctid && (
                      <tr className="product-row">
                        <td className="product-heading">Product ID: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.prodcuctid,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.size && (
                      <tr className="product-row">
                        <td className="product-heading">Size: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.size,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.thickness && (
                      <tr className="product-row">
                        <td className="product-heading">Thickness: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.thickness,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.weight && (
                      <tr className="product-row">
                        <td className="product-heading">Weight: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.weight,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.color && (
                      <tr className="product-row">
                        <td className="product-heading">Color: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.color,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.packaging_size && (
                      <tr className="product-row">
                        <td className="product-heading">Packaging Size: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.packaging_size,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.packaging_type && (
                      <tr className="product-row">
                        <td className="product-heading">Packaging Type: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.packaging_type,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.usage_application && (
                      <tr className="product-row">
                        <td className="product-heading">Usage Application: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.usage_application,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.storage_instruction && (
                      <tr className="product-row">
                        <td className="product-heading">
                          Storage Instruction:{" "}
                        </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.storage_instruction,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.self_line && (
                      <tr className="product-row">
                        <td className="product-heading">Self life: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.self_line,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.dimensions && (
                      <tr className="product-row">
                        <td className="product-heading">Dimensions: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.dimensions,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}

                    {obj.description && (
                      <tr className="product-row">
                        <td className="product-heading">Description: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.description,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.details && (
                      <tr className="product-row">
                        <td className="product-heading">Details: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{ __html: obj.details }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.info && (
                      <tr className="product-row">
                        <td className="product-heading">Information: </td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{ __html: obj.info }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.label1 && (
                      <tr className="product-row">
                        <td className="product-heading">{obj.label1}</td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.label1_info,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.label2 && (
                      <tr className="product-row">
                        <td className="product-heading">{obj.label2}</td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.label2_info,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.label3 && (
                      <tr className="product-row">
                        <td className="product-heading">{obj.label3}</td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.label3_info,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.label4 && (
                      <tr className="product-row">
                        <td className="product-heading">{obj.label4}</td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.label4_info,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                    {obj.label5 && (
                      <tr className="product-row">
                        <td className="product-heading">{obj.label5}</td>
                        <td className="product-detail">
                          <span
                            className="user-enter"
                            dangerouslySetInnerHTML={{
                              __html: obj.label5_info,
                            }}
                          ></span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <br />
            <Row className="g-4">
              <Col md={5}></Col>
              <Col md={2} className="text-center">
                <Link to="/contact">
                  <button className="btn btn-primary app-button">
                    Connect with us
                  </button>
                </Link>
              </Col>
              <Col md={5}></Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const getDynamicClass = () => {
    if (
      props.page === "SEED" ||
      props.page === "HANDICRAFT" ||
      props.page === "BAMBOO"
    ) {
      return "col-md-3 my-card";
    } else if (
      props.page === "JAALIS" ||
      props.page === "DECORATIVE_TILES" ||
      props.page === "CladdingBricksWallBricks" ||
      props.page === "RoofingTiles" ||
      props.page === "TerracottaFloaring" ||
      props.page === "TerracottaPanel" ||
      props.page === "Bricks"
    ) {
      return "col-md-4 my-card col";
    }
    return "col-sm-4 my-card";
  };

  return (
    <>
      {props.page === "CLAY" &&
        props.data.map((object, i) => {
          return (
            <div key={`cntr-${object.id}`} className={getDynamicClass()}>
              <Link to={object.url} className="text-decoration-none app-link">
                <List {...object} />
              </Link>
            </div>
          );
        })}

      {(props.page === "JAALIS" ||
        props.page === "DECORATIVE_TILES" ||
        props.page === "Bricks" ||
        props.page === "RoofingTiles" ||
        props.page === "TerracottaFloaring" ||
        props.page === "TerracottaLockingPavers" ||
        props.page === "TerracottaPanel" ||
        props.page === "HollowBlock" ||
        props.page === "CladdingBricksWallBricks") && (
        <Row xs={1} md={2} className="g-4">
          {props.data.map((object, i) => {
            return (
              <div
                key={`cntr-${object.id}`}
                className={getDynamicClass()}
                title="Click here for more information"
              >
                <List {...object} />{" "}
              </div>
            );
          })}
        </Row>
      )}

      {(props.page === "SEED" ||
        props.page === "HANDICRAFT" ||
        props.page === "BAMBOO") && (
        <Row xs={1} md={2} className="g-4">
          {props.data.map((object, i) => {
            return (
              <div key={`cntr-${object.id}`} className={getDynamicClass()}>
                <List {...object} />{" "}
              </div>
            );
          })}
        </Row>
      )}

      {props.data && props.data.length === 0 && (
        <div className="coming-soon text-center">
          <h1>Coming Soon</h1>
        </div>
      )}

      <ModalPopup />
    </>
  );
};

export default ProductList;
