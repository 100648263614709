import React from "react";

import Meta from "../components/Meta";

const Membership = () => {
  // page content
  const pageTitle = "Membership & Registration";

  return (
    <div className="about">
      <Meta title={pageTitle} />
      <h2 className="cms-page">Membership & Registration</h2>
      <br />
      <img
        src="./assets/img/Membership/DGFT.jpg"
        className="card-img-top-1 responsive"
        alt="DGFT"
      />

      <img
        src="./assets/img/Membership/FIEO.jpg"
        className="card-img-top-1 responsive"
        alt="FIEO"
      />

      <img
        src="./assets/img/Membership/GST.jpg"
        className="card-img-top-1 responsive"
        alt="GST"
      />

      <img
        src="./assets/img/Membership/IEC.jpg"
        className="card-img-top-1 responsive"
        alt="IEC"
      />
      <img
        src="./assets/img/Membership/MSME.jpg"
        className="card-img-top-1 responsive"
        alt="MSME"
      />
      <img
        src="./assets/img/Membership/UdyogAadhaar.png"
        className="card-img-top-1 responsive"
        alt="Udyog Aadhaar"
      />
    </div>
  );
};

export default Membership;
