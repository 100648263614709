import React from 'react';
import Hoc from "../../components/hoc";
import ProductList from "../../components/list";
import Header from "../../components/Header";

const Seed = () => {
  const [data,setData] = React.useState([]);

  React.useEffect(()=>{
      fetch('./dataApi/seed.json').then(response=>response.json()).then(json=>setData(json))
  },[]);

  const Product = Hoc(ProductList, data, true,false,"SEED");
  const breadcrumbs = [
    { title: "Home", url: "/" },
    { title: "Products", url: "/products" },
    { title: "Seed", url: "" },
  ];
  return (
    <Header
      pageTitle="Seed Products"
      breadcrumbs={breadcrumbs}
      currentPage="Seed Products"
    >
      <Product />
    </Header>
  );
};

export default Seed;
