import React from "react";
import Hoc from "../../components/hoc";
import ProductList from "../../components/list";
import Header from "../../components/Header";
import ProductListView from "../../layout/ProductList";

const Bricks = (props) => {
  const [data,setData] = React.useState([]);

  React.useEffect(()=>{
      fetch('./dataApi/BRICKS.json').then(response=>response.json()).then(json=>setData(json))
  },[]);

  const MyComponent = Hoc(ProductList, data, true, true, "Bricks");
  const breadcrumbs = [
    { title: "Home", url: "/" },
    { title: "Products", url: "/products" },
    { title: "TERRACOTTA CLAY Product", url: "/clay" },
    { title: "Bricks", url: "" },
  ];
  return (
    <Header pageTitle="Bricks" breadcrumbs={breadcrumbs} currentPage="Bricks">
      <img
        src="./assets/img/BRICKS/Final Brick Strip.jpg"
        className="img-fluid"
        alt="Bricks"
      />
      <ProductListView
        cmp={<MyComponent />}
        description={
          <div className="product-desc">
            <p>
              Bricks are the most fundamental building material used by humanity
              for centuries.
            </p>
            <p>
              Bricks are all-natural, reusable, and recyclable, making them the
              most sustainable wall construction product.
            </p>
            <p>The creative potential of wire-cut bricks is immense.</p>
            <p>
              The primary use is to avoid plastering and keep the natural look
              for both interior and exterior.
            </p>
            <p>
              Besides that, you can make so many patterns by installing them in
              various modes. plaster is not necessarily and therefore using it
              could be cost-saving.
            </p>
            <p>
              Plaster is not necessarily and therefore using it
              could be cost-saving.
            </p>
          </div>
        }
      />
    </Header>
  );
};

export default Bricks;
